// @import "./assets/scss/variables.scss";
// Roboto
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// KoPubWorldDotum
@font-face {
  font-family: "KoPubWorldDotum";
  font-weight: 300;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumLight.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumLight.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumLight.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumLight.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumLight.ttf")
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "KoPubWorldDotum";
  font-weight: 500;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumMedium.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumMedium.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumMedium.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumMedium.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumMedium.ttf")
      format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "KoPubWorldDotum";
  font-weight: 700;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumBold.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumBold.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumBold.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/kopus/KoPubWorldDotumBold.ttf")
      format("truetype");
  font-display: swap;
}
body {
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
[role="button"] {
  cursor: pointer;
}
.hidden-scrollbar {
  scrollbar-width: none; /* Hides the scrollbar in Firefox */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none; /* Hides the scrollbar in Internet Explorer and Edge */
}
.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* Hides the scrollbar in Chrome, Safari, and Opera */
}
// @media screen and (max-width: 768px){
//   ::-webkit-scrollbar {
//       -webkit-appearance: none;
//   }
// }

input {
  cursor: default !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  &:focus {
    outline: none;
  }
  &::placeholder {
    opacity: 0.4;
  }
}
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s; // set default animation to 0s
}

.back-new-mobile {
  position: absolute;
  left: 16px;
  top: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    @extend .block;
  }
}

// NewCSS

.Mui-focused {
  fieldset {
    border-width: 1px !important;
    border-color: var(--accent-sell, #1554f6) !important;
  }
}
/* text */
.text-underline {
  text-decoration-line: underline;
  text-underline-position: under;
}
.decoration-none {
  text-decoration: none;
  text-decoration-line: none;
}
.text-ellipsis {
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-pack: end;
  overflow: hidden;
  vertical-align: bottom;
}
.text-ellipsis-1 {
  @extend .text-ellipsis;
  -webkit-line-clamp: 1;
}
.text-ellipsis-2 {
  @extend .text-ellipsis;
  -webkit-line-clamp: 2;
}
.text-ellipsis-3 {
  @extend .text-ellipsis;
  -webkit-line-clamp: 3;
}
.text-detail-dangerously {
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}
.text-detail-dangerously-15 p {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.caption {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #9497a0;
}

.disabled {
  opacity: 0.5;
}
.no-wrap {
  text-wrap: nowrap;
}
.font-1 {
  font-family: var(--font-1, "KoPubWorldDotum");
}
.pre-wrap {
  white-space: pre-wrap;
}
.normal-wrap {
  white-space: normal;
}
.font-2 {
  font-family: var(--font-2, "'Roboto', sans-serif");
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-22{
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}

.fs-18 {
  font-size: 18px;
}

.fs-50 {
  font-size: 50px;
}

// blarge = h4 + fw-500
// bmed = h5 + fw-500
// bsmall = h6 + fw-500
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}
.lh-48 {
  line-height: 48px;
}
.lh-32 {
  line-height: 32px;
}
.lh-28 {
  line-height: 28px;
}
.lh-26 {
  line-height: 26px;
}
.lh-24 {
  line-height: 24px;
}
.lh-23 {
  line-height: 23px;
}
.lh-22 {
  line-height: 22px;
}
.lh-20 {
  line-height: 20px;
}
.lh-19 {
  line-height: 19px;
}
.lh-18 {
  line-height: 18px;
}
.lh-16 {
  line-height: 16px;
}
.lh-15 {
  line-height: 15px;
}
.lh-14 {
  line-height: 14px;
}
.lh-12 {
  line-height: 12px;
}
.lh-11 {
  line-height: 11px;
}

// padding
.pd-0-8 {
  padding: 0 8px;
}
.pd-0-12 {
  padding: 0 12px;
}
.pd-0-16 {
  padding: 0 16px;
}
.pd-0-20 {
  padding: 0 20px;
}
.pd-0-24 {
  padding: 0 24px;
}
.pd-0-32 {
  padding: 0 32px;
}
.pd-percent {
  padding: 0.8px 12px 0.2px 13px;
}
.pd-1-0 {
  padding: 1px 0;
}
.pd-1-12 {
  padding: 1px 12px;
}
.pd-2-4 {
  padding: 2px 4px;
}
.pd-2-12 {
  padding: 2px 12px;
}
.pd-3 {
  padding: 3px;
}
.pd-3-0 {
  padding: 3px 0;
}
.pd-3-8 {
  padding: 3px 8px;
}
.pd-3-16 {
  padding: 3px 16px;
}
.pd-4 {
  padding: 4px;
}
.pd-4-4-4-0 {
  padding: 4px 4px 4px 0;
}

.pd-4-8-4-0 {
  padding: 4px 8px 4px 0;
}
.pd-4-12 {
  padding: 4px 12px;
}
.pd-4-16 {
  padding: 4px 16px;
}
.pd-4-20 {
  padding: 4px 20px;
}
.pd-4-24 {
  padding: 4px 24px;
}
.pd-6 {
  padding: 6px;
}
.pd-6-0-6-12 {
  padding: 6px 0px 6px 12px;
}
.pd-7 {
  padding: 7px;
}
.pd-7-16 {
  padding: 7px 16px;
}
.pd-8 {
  padding: 8px;
}
.pd-8-0 {
  padding: 8px 0;
}
.pd-8-4-8-8 {
  padding: 8px 4px 8px 8px;
}
.pd-8-12 {
  padding: 8px 12px;
}
.pd-8-16 {
  padding: 8px 16px;
}
.pd-8-24 {
  padding: 8px 24px;
}
.pd-85-16 {
  padding: 8.5px 16px;
}
.pd-8-20 {
  padding: 8px 20px;
}
.pd-9-0 {
  padding: 9px 0;
}
.pd-9-20 {
  padding: 9px 20px;
}
.pd-10-20 {
  padding: 10px 20px;
}
.pd-11-0 {
  padding: 11px 0px;
}
.pd-12 {
  padding: 12px;
}
.pd-12-0 {
  padding: 12px 0;
}
.pd-12-2 {
  padding: 12px 2px;
}
.pd-12-2-10-2 {
  padding: 12px 2px 10px 2px;
}
.pd-12-20 {
  padding: 12px 20px;
}
.pd-12-24 {
  padding: 12px 24px;
}
.pd-16 {
  padding: 16px;
}
.pd-16-0 {
  padding: 16px 0;
}
.pd-16-20 {
  padding: 16px 20px;
}
.pd-17-0 {
  padding: 17px 0;
}
.pd-21-24 {
  padding: 21px 24px;
}
.pd-24 {
  padding: 24px;
}
.pd-24-0 {
  padding: 24px 0;
}
.pd-24-16 {
  padding: 24px 16px;
}
.pd-32 {
  padding: 32px;
}
.mui-menu-none-padding {
  .MuiList-root {
    padding: 0;
  }
}

// padding-bottom
.pb-200 {
  padding-bottom: 200px;
}
.pb-128 {
  padding-bottom: 128px;
}
.pb-64 {
  padding-bottom: 64px;
}
.pb-48 {
  padding-bottom: 48px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-18 {
  padding-bottom: 18px;
}
.pb-16 {
  padding-bottom: 16px !important;
}
.pb-12 {
  padding-bottom: 12px;
}
.pb-9 {
  padding-bottom: 9px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-2 {
  padding-bottom: 2px;
}
.pb-0 {
  padding-bottom: 0;
}
// padding - right
.pr-0 {
  padding-right: 0;
}
.pr-4 {
  padding-right: 4px;
}
.pr-12 {
  padding-right: 12px;
}
.pr-13 {
  padding-right: 13px;
}
.pr-14 {
  padding-right: 14px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-24 {
  padding-right: 24px;
}
.pr-32 {
  padding-right: 32px;
}
.pr-44 {
  padding-right: 44px;
}
// padding - left
.pl-4 {
  padding-left: 4px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-12 {
  padding-left: 12px;
}
.pl-14 {
  padding-left: 14px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-17 {
  padding-left: 17px;
}
.pl-18 {
  padding-left: 18px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-pr-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.pl-24 {
  padding-left: 24px;
}
.pl-32 {
  padding-left: 32px;
}
.pl-36 {
  padding-left: 36px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-44 {
  padding-left: 44px;
}
.pl-60 {
  padding-left: 60px;
}
// padding
.pt-1 {
  padding-top: 1px;
}
.pt-2 {
  padding-top: 2px;
}
.pt-3 {
  padding-top: 3px;
}
.pt-8 {
  padding-top: 8px;
}
.pt-9 {
  padding-top: 9px;
}
.pt-12 {
  padding-top: 12px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-24 {
  padding-top: 24px;
}
.pt-32 {
  padding-top: 32px;
}
.pt-56 {
  padding-top: 56px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-64 {
  padding-top: 64px;
}

.text-align-left {
  text-align: left;
}
.text-centered {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.italic {
  font-style: italic;
}
.uppercase {
  text-transform: uppercase;
}

/* layout */
.block {
  display: block;
}
.h-1px {
  @extend .block;
  height: 1px;
}
.h-11px {
  @extend .block;
  height: 11px;
}
.h-14px {
  @extend .block;
  height: 14px;
}
.h-16px {
  @extend .block;
  height: 16px;
}
.h-18px {
  @extend .block;
  height: 18px;
}
.h-24px {
  @extend .block;
  height: 24px;
}
.h-32px {
  @extend .block;
  height: 32px;
}
.h-38px {
  @extend .block;
  height: 38px;
}
.h-48px {
  @extend .block;
  height: 48px;
}
.h-100vh {
  @extend .block;
  height: 100vh;
}
.w-8px {
  @extend .block;
  width: 8px;
}
.w-20px {
  @extend .block;
  width: 20px;
}
.w-24 {
  width: 24px;
}
.w-30px {
  @extend .block;
  width: 30px;
}
.w-104px {
  @extend .block;
  width: 104px;
}
.w-120px {
  @extend .block;
  width: 120px;
}
.w-268px {
  @extend .block;
  width: 268px;
}
.w-1256px {
  @extend .block;
  width: 1256px;
}
.mw-none {
  min-width: unset !important;
}
.mw-120px {
  @extend .block;
  min-width: 120px;
}
.maxh-60px {
  max-height: 60px;
}

.w-100vw {
  @extend .block;
  width: 100vw;
}

.h-40px {
  height: 40px;
}

.h-42px {
  height: 42px;
}

.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.transform-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
// this class wrap all the content between the header and footer
.content {
  // position: relative;
  padding-bottom: 128px;
  display: flex;
  flex-direction: column;
  gap: 56px;
  .swiper-slide {
    width: fit-content;
  }
}

.content-no-pb {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.fit-swiper {
  .swiper-slide {
    width: fit-content;
  }
}
// svg
.svg-pd-5-3 {
  svg {
    width: 16px;
    height: 16px;
    padding: 5.53px 3.53px;
  }
}
.svg-pd-6-0-6-12 {
  svg {
    padding: 6px 0px 6px 12px;
  }
}
.svg-24px {
  @extend .block-24px;
  svg {
    width: 24px;
    height: 24px;
  }
}
.svg-20px {
  @extend .block-20px;
  svg {
    width: 20px;
    height: 20px;
  }
}
.svg-18px {
  svg {
    width: 18px;
    height: 18px;
  }
}
.svg-neutral-6 {
  svg path {
    fill: var(--neutral-color-6, #cfd0d2);
  }
}
.svg-neutral-5 {
  svg path {
    fill: var(--neutral-color-5, #afb1b8);
  }
}
.svg-sell {
  svg path {
    fill: var(--accent-sell, #1554f6);
  }
}
.svg-hover-neutral-4:hover {
  svg path {
    fill: var(--interaction-info-color, #9497a0);
  }
}

.full-width {
  width: 100%;
}
.auto-width {
  width: auto;
}
.fit-width {
  width: fit-content;
}
.fit-height {
  height: min-content;
}
.full-height {
  height: 100%;
}
.auto-height {
  height: auto;
}
.inline-block {
  display: inline-block;
}
.grid {
  display: grid;
}
.grid-10-item {
  @extend .grid;
  grid-template-columns: repeat(10, 1fr);
}
.grid-6-item {
  @extend .grid;
  grid-template-columns: repeat(6, 1fr);
}

.grid-2-item {
  @extend .grid;
  grid-template-columns: repeat(2, 1fr);
}
.gird-space-24-4 {
  grid-column-gap: 24px;
  grid-row-gap: 4px;
}

.gird-space-32-16 {
  grid-column-gap: 32px;
  grid-row-gap: 16px;
}

.grid-4-item {
  @extend .grid;
  grid-template-columns: repeat(4, 1fr);
}

.grid-index:nth-last-child(2) {
  grid-column: 1;
}
.grid-index:nth-last-child(1) {
  grid-column: 5;
}

.layout-2-col {
  // grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-template-columns: repeat(auto-fill, minmax(322px, 1fr));
}

.grid-col-gap-24 {
  grid-column-gap: 24px;
}

.grid-row-gap-4 {
  grid-row-gap: 4px;
}
.hidden {
  display: none !important;
}

// Flex
.flex {
  display: flex !important;
}
.flex-col {
  @extend .flex;
  flex-direction: column;
}
.flex-1 {
  flex: 1;
}
.flex-1-auto {
  flex: 1 1 auto;
}
.flex-evenly {
  @extend .flex;
  justify-content: space-evenly;
}
.flex-between {
  @extend .flex;
  justify-content: space-between;
}
.flex-between-center {
  @extend .flex-between;
  align-items: center;
}
.flex-between-start {
  @extend .flex-between;
  align-items: flex-start;
}
.flex-between-end {
  @extend .flex-between;
  align-items: flex-end;
}
.flex-start {
  @extend .flex;
  justify-content: flex-start;
}
.flex-start-end {
  @extend .flex-start;
  align-items: flex-end;
}
// center using for div, baseline using for text
.flex-start-baseline {
  @extend .flex-start;
  align-items: baseline;
}

.flex-start-start {
  @extend .flex-start;
  align-items: flex-start;
}

.flex-end {
  @extend .flex;
  justify-content: flex-end;
}
.flex-start-center {
  @extend .flex-start;
  align-items: center;
}
.flex-end-center {
  @extend .flex-end;
  align-items: center;
}
.flex-center-start {
  @extend .flex;
  justify-content: center;
  align-items: flex-start;
}
.flex-centered {
  @extend .flex;
  justify-content: center;
  align-items: center;
}
.flex-center {
  @extend .flex;
  align-items: center;
}
.flex-col-center {
  @extend .flex-col;
  align-items: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-between-start {
  @extend .flex-between;
  align-items: flex-start;
}

.flex-between-baseline {
  @extend .flex-between;
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}
.inline-block {
  display: inline-block;
}
.inline-flex {
  display: inline-flex;
}
.gap-1 {
  gap: 1px;
}
.gap-2 {
  gap: 2px;
}
.gap-4 {
  gap: 4px;
}
.gap-6 {
  gap: 6px;
}
.gap-7 {
  gap: 7px;
}
.gap-8 {
  gap: 8px;
}
.row-gap-8 {
  row-gap: 8px;
}
.col-gap-8 {
  column-gap: 8px;
}
.gap-12 {
  gap: 12px;
}
.gap-16 {
  gap: 16px;
}
.gap-21 {
  gap: 21px;
}
.gap-24 {
  gap: 24px;
}
.gap-32 {
  gap: 32px;
}
.gap-40 {
  gap: 40px;
}
.gap-50 {
  gap: 50px;
}
.gap-56 {
  gap: 56px;
}
.gap-64 {
  gap: 64px;
}
/* Color */
.color-buy {
  color: var(--accent-buy, #f20013);
}
.color-buy-75 {
  color: var(--accent-buy-75, rgba(242, 0, 19, 0.75));
}
.color-sell {
  color: var(--accent-sell, #1554f6);
}
.color-sell-75 {
  color: var(--accent-sell-75, #507ff8);
}
.color-neutral {
  color: var(--accent-neutral, #45495a);
}
.color-info {
  color: var(--interaction-info-color, #9497a0);
}
.color-neutral-1 {
  color: var(--neutral-color-1, #11131a);
}
.color-neutral-2 {
  color: var(--neutral-color-2, #373d3f);
}
.color-neutral-3 {
  color: var(--neutral-color-3, #45495a);
}
.color-neutral-4 {
  color: var(--neutral-color-4, #9497a0);
}
.color-neutral-5 {
  color: var(--neutral-color-5, #afb1b8);
}
.color-neutral-6 {
  color: var(--neutral-color-6, #cfd0d2);
}
.color-neutral-7 {
  color: var(--neutral-color-7, #e4e5e7);
}
.color-neutral-10 {
  color: var(--neutral-color-10, #ffffff);
}
// Background
.bg-neutral {
  background: var(--accent-neutral, #45495a);
}
.bg-neutral-1 {
  background: var(--neutral-color-1, #11131a);
}
.bg-neutral-2 {
  background: var(--neutral-color-2, #373d3f);
}
.bg-neutral-4 {
  background: var(--neutral-color-4, #9497a0);
}
.bg-neutral-5 {
  background: var(--neutral-color-5, #afb1b8);
}
.bg-neutral-6 {
  background: var(--neutral-color-6, #cfd0d2);
}
.bg-neutral-7 {
  background: var(--neutral-color-7, #e4e5e7);
}
.bg-neutral-8 {
  background: var(--neutral-color-8, #f4f4f4);
}
.bg-neutral-9 {
  background: var(--neutral-color-9, #f9f9f9);
}
.bg-neutral-10 {
  background: var(--neutral-color-10, #ffffff);
}
.bg-neutral-11 {
  background: #f3f6fe;
}
.bg-sell {
  background-color: var(--accent-sell, #1554f6);
}

.bg-selected {
  background-color: rgba(21, 84, 246, 0.05);
}
.bg-buy {
  background-color: var(--accent-buy, #f20013);
}
.bg-secondary-color-2 {
  background-color: var(--secondary-color-2, #f4cb5d);
}
.bg-white {
  background-color: white;
}
.bg-trans {
  background: transparent;
}
.bg-list-selected {
  background-color: rgba(94, 134, 244, 0.06);
}
.mui-custom-auto-complete-dropdown {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  @extend .border-1px-neutral-7;
  margin-top: 8px;
  border-radius: 4px;
}

/* img & btn - icon */
.img-box-shadow {
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
}
.img-cover {
  object-fit: cover;
}
.img-contain {
  object-fit: contain;
}
.img-fill {
  object-fit: fill;
}
.circle_img {
  @extend .block-24px;
  @extend .img-cover;
  @extend .border-rounded;
  @extend .img-box-shadow;
}

.circle_img_40 {
  @extend .block-40px;
  @extend .img-cover;
  @extend .border-rounded;
  @extend .img-box-shadow;
}

.block-16px {
  @extend .block;
  width: 16px;
  height: 16px;
}
.block-18px {
  @extend .block;
  width: 18px;
  height: 18px;
}
.block-20px {
  @extend .block;
  width: 20px;
  height: 20px;
}
.block-24px {
  @extend .block;
  width: 24px;
  height: 24px;
}
.block-32px {
  @extend .block;
  width: 32px;
  height: 32px;
}
.block-36px {
  @extend .block;
  width: 36px;
  height: 36px;
}
.block-40px {
  @extend .block;
  width: 40px;
  height: 40px;
}
.block-56px {
  @extend .block;
  width: 56px;
  height: 56px;
}

.block-112px {
  width: 224px;
  height: 224px;
}

.btn-36px-end {
  @extend .block-36px;
  padding: 6px 0 6px 12px !important;
}
.btn-36px-start {
  @extend .block-36px;
  padding: 8px 16px 8px 0 !important;
}
.btn-32px-end {
  @extend .block-32px;
  padding: 4px 0 4px 8px !important;
}
.btn-40px-start {
  @extend .block-40px;
  padding: 8px 16px 8px 0 !important;
}
.btn-40px-end {
  @extend .block-40px;
  padding: 8px 0 8px 16px !important;
}
.btn-40px-end-9-7 {
  @extend .block-40px;
  padding: 9px 0 7px 16px !important;
}
.btn-40px-top-right {
  @extend .block-40px;
  padding: 0 0 16px 16px !important;
}
.btn-40px-center {
  @extend .block-40px;
  @extend .flex-centered;
}

.btn-40px-end-pc {
  padding: 9.5px 0px 6.5px 16px !important;
}

.btn-56px-center {
  @extend .block-56px;
  @extend .flex-centered;
}
.bnt-96-40 {
  @extend .flex-centered;
  width: 96px;
  height: 40px;
}

.btn-96-40-cancel {
  @extend .bnt-96-40;
  @extend .bg-neutral-10;
  @extend .border-1px-neutral-7;
  @extend .br-20;
}
.btn-96-40-submit {
  @extend .bnt-96-40;
  @extend .bg-sell;
  @extend .br-20;
}

.btn-36px-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  padding: 6px 0 6px 12px;

  button {
    display: block;
    width: 14px;
    height: 14px;
  }
}

.border-none {
  border: none;
}
.border-1px-sell {
  border: 1px solid var(--accent-sell, #1554f6);
}
.border-1px-neutral-5 {
  border: 1px solid var(--neutral-color-5, #afb1b8);
}
.border-1px-neutral-1 {
  border: 1px solid var(--neutral-color-1, #11131a);
}
.border-1px-bot-neutral-3 {
  border-bottom: 1px solid var(--neutral-color-3, #45495a);
}
.border-1px-neutral-4 {
  border: 1px solid var(--neutral-color-4, #9497a0);
}
.border-1px-neutral-6 {
  border: 1px solid var(--neutral-color-6, #cfd0d2);
}
.border-1px-neutral-7 {
  border: 1px solid var(--neutral-color-7, #e4e5e7);
}
.border-1px-top-neutral-7 {
  border-top: 1px solid var(--neutral-color-7, #e4e5e7);
}
.border-1px-bottom-neutral-7 {
  border-bottom: 1px solid var(--neutral-color-7, #e4e5e7);
}
.border-1px-right-neutral-7 {
  border-right: 1px solid var(--neutral-color-7, #e4e5e7);
}
.border-1px-left-neutral-7 {
  border-left: 1px solid var(--neutral-color-7, #e4e5e7);
}
.border-1px-neutral-8 {
  border: 1px solid var(--neutral-color-8, #f4f4f4);
}
.border-1px-top-neutral-8 {
  border-top: 1px solid var(--neutral-color-8, #f4f4f4);
}
.border-1px-right-neutral-8 {
  border-right: 1px solid var(--neutral-color-8, #f4f4f4);
}
.border-1px-left-neutral-8 {
  border-left: 1px solid var(--neutral-color-8, #f4f4f4);
}
.border-1px-bot-neutral-8 {
  border-bottom: 1px solid var(--neutral-color-8, #f4f4f4);
}
.border-2px-bot-neutral-1 {
  border-bottom: 2px solid var(--neutral-color-1, #11131a);
}
.border-1px-topbot-neutral-7 {
  border-top: 1px solid var(--neutral-color-7, #e4e5e7);
  border-bottom: 1px solid var(--neutral-color-7, #e4e5e7);
}
.border-rounded {
  border-radius: 50%;
}
.br-2 {
  border-radius: 2px;
}
.br-4 {
  border-radius: 4px;
}
.br-12 {
  border-radius: 12px;
}
.br-14 {
  border-radius: 14px;
}
.br-16 {
  border-radius: 16px;
}
.br-20 {
  border-radius: 20px;
}
.br-24 {
  border-radius: 24px;
}
.br-28 {
  border-radius: 28px;
}
.br-40 {
  border-radius: 40px;
}
.border-collapse {
  border-collapse: collapse;
}
.border-1px-neutral-8 {
  border: 1px solid var(--neutral-color-8, #f4f4f4);
}
.border-1px-neutral-9 {
  border: 1px solid var(--neutral-color-9, #f9f9f9);
}

.rotate-8deg {
  transform: rotate(8deg);
}

// Other CSS
.last-padding {
  position: relative;
  padding-right: 4px;
}
.last-padding::after {
  font-size: 11px;
  color: transparent;
  line-height: 14px;
  content: ".";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.PrivateSwipeArea-root {
  display: none;
}

.custom-tab-mui {
  .MuiTabs-root {
    border-bottom: 1px solid var(--neutral-color-7);
  }

  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    color: var(--neutral-color-1, #11131a);
    /* padding: 12px 2px; not necessary, padding will make width change, but padding is in the design so i put it here*/
  }
  .MuiTabPanel-root {
    padding: 0 20px;
  }
  // .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.MuiTabs-scrollButtons,
  // .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled.MuiTabs-scrollButtons {
  //   // display: none;
  // }
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    padding: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: unset;
    color: var(--interaction-info-color, #9497a0);
    font-family: var(--font-1, "KoPubWorldDotum");
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .MuiTabs-flexContainer {
    gap: 16px;
    align-items: center;
  }
}
.bottom-alert-modal {
  padding: 0;
  background-color: var(--neutral-color-1);
  margin: 0 32px 10px 32px;
  min-height: min-content; // override the default
  .Toastify__toast-body {
    padding: 12px 24px;
    display: flex;
    column-gap: 12px;
    margin: 0;
    height: fit-content;
    div {
      color: var(--neutral-color-10);
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 700;
      // line-height: 48px; check it out because when text so long, the alert could be very tall.
      letter-spacing: 5%;
    }
  }
}
.bottom-alert-modal:last-child {
  margin: 0 32px 96px 32px;
}
.center-alert-modal {
  padding: 0;
  background-color: var(--neutral-color-10);
  margin: 0;
  min-height: min-content; // override the default
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flow-auto {
  overflow: auto;
}
.flow-x-auto {
  overflow-x: auto;
}
.flow-y-auto {
  overflow-y: auto;
}
.flow-hidden {
  overflow: hidden;
}

// margin
.mg-none {
  margin: unset !important;
}
.mg-8-12 {
  margin: 8px 12px;
}

// margin - left
.ml-8 {
  margin-left: 8px;
}

.ml-24 {
  margin-left: 24px;
}
// margin - right
.mr-8 {
  margin-right: 8px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-24 {
  margin-right: 24px;
}
// margin - top
.mt-none {
  margin-top: 0;
}
.mt-2 {
  margin-top: 2px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-56 {
  margin-top: 56px;
}
.mt-64 {
  margin-top: 64px;
}
// margin-bottom
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-56 {
  margin-bottom: 56px;
}
.mb-64 {
  margin-bottom: 64px;
}
// button
.report-btn {
  padding: 8.5px 24px;
  background-color: var(--color-buy, #f20013);
  border-radius: 20px;
  gap: 8px;
  width: fit-content;
  color: white;
  white-space: nowrap;
}

.submit-btn {
  padding: 8.5px 24px;
  background-color: var(--accent-sell, #1554f6);
  border-radius: 20px;
  gap: 8px;
  width: fit-content;
  color: white;
  white-space: nowrap;
  transition: 0.3s;
}

.cancel-btn {
  padding: 8.5px 24px;
  background-color: var(--neutral-color-10, #ffffff);
  border: 1px solid var(--neutral-color-7, #e4e5e7);
  border-radius: 20px;
  gap: 8px;
  width: fit-content;
  border-radius: 20px;
  white-space: nowrap;
}

.cancel-btn-pc {
  @extend .cancel-btn;
  width: 96px;
}

.submit-btn-pc {
  @extend .submit-btn;
  width: 96px;
}

.badge-btn {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  border-radius: 40px;
  width: fit-content;
}

.input-field {
  height: 48px;
  // background-color: var(--neutral-color-8, #F4F4F4);
  border-radius: 24px;
  padding: 13px 20px;
  border: none;
  min-width: 266px;
}

.pointer {
  cursor: pointer;
}

.active-badge {
  color: var(--neutral-color-10, #ffffff);
}

.period {
  display: flex;
  align-items: center;
  gap: 8px;
  ._period {
    border-radius: 2px;
    border-bottom: 1px solid var(--F4F4F4, #f4f4f4);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 11px 0px;
    align-items: center;

    button {
      display: inline-flex;
    }
  }
}

.time {
  font-size: 12px;
  line-height: 18px;
}

.body-padding {
  padding: 32px 20px 0px 20px;
}

.break-all {
  word-break: break-all;
}
.word-break {
  word-break: break-all;
}

.post-content a {
  text-decoration-line: underline;
  text-underline-position: under;
  color: var(--accent-sell, #1554f6);
}

// .post-content img {
//   max-width: 400px;
// }

.max-width-20vw {
  max-width: 20vw;
}

.max-width-30vw {
  max-width: 30vw;
}

.is-tablet {
  margin-left: 150px;
  margin-right: 150px;
  overflow-x: hidden;
}

// PC CSS

.custom-pc-scroll::-webkit-scrollbar {
  width: 2px;
  background-color: var(--neutral-color-8, #f4f4f4);
  //   ::-webkit-scrollbar {
  //   width: 4px;
  //   background-color: var(--neutral-color-8, #f4f4f4);
  // }
}
.custom-pc-scroll::-webkit-scrollbar-thumb {
  background-color: var(--neutral-color-6, #cfd0d2);
  //   ::-webkit-scrollbar-thumb {
  //   background-color: var(--neutral-color-6, #cfd0d2);
  // }
}

.custom-tab-mui.pc {
  .MuiTabs-root {
    min-height: min-content;
  }
  .MuiTabPanel-root {
    padding: 0;
  }
  .MuiTabs-indicator {
    height: 3px;
  }
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    padding: 0 4px 8px 4px;
    height: 34px;
    min-height: min-content;
  }
  .MuiTabs-flexContainer {
    gap: 24px;
    align-items: center;
  }
}

h1.pc {
  font-size: 24px;
  line-height: 32px;
}

h1.pc-huge {
  font-size: 32px;
  line-height: 40px;
}

h2.pc {
  font-size: 22px;
  line-height: 30px;
}

h3.pc {
}

.uploadImgChat {
  width: 100% !important;
  max-width: 50vw !important;
  height: auto !important;
  border: none !important;
  border-radius: 0 !important;
  cursor: context-menu;
}

.hide-empty:empty {
  display: none;
}

.text-hover-accent-sell:hover {
  text-decoration-line: underline;
  text-underline-position: under;
  color: var(--accent-sell, #1554f6);
}

.interest-button:hover {
  svg path {
    fill: #9497a0;
  }
}
